import { render, staticRenderFns } from "./WXblank2.vue?vue&type=template&id=4bfd3b3a&scoped=true&"
import script from "./WXblank2.vue?vue&type=script&lang=js&"
export * from "./WXblank2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bfd3b3a",
  null
  
)

export default component.exports